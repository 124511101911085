html {
	font-size: $base-font-size;
}

body {
	font-family: 'Yu Gothic';
	font-weight: 500;
	color: $base;
}

p {
	line-height: 1.9;
}

label {
	cursor: pointer;
}

input {
	background-color: #FFF;

	&:disabled {
		filter: grayscale(100%) contrast(75%);
	}
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="number"] {
	width: 100%;
	padding: 8px;
	border: 1px solid $gray2;
	line-height: 1;

	&::placeholder {
		color: $gray4;
	}
}

input[type="number"] {
	-moz-appearance: textfield;
}


// 丸に中丸
input[type="radio"],
input[type="checkbox"] {
	appearance: none;
	width: 18px;
	height: 18px;
	margin-right: 8px;
	border-radius: 50%;
	border: 2px solid $gray4;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;

	&:checked {
		border: none;
		background-image: url(../img/radio-on.svg);
	}
}

textarea {
	@include scrollBar(0);
	width: 100%;
	padding: 8px;
	border: 1px solid $gray2;
	background-color: #FFF;
	line-height: 1.9;

	&:disabled {
		filter: grayscale(100%) contrast(75%);
	}

	&::placeholder {
		color: $gray4;
	}
}


select {
	position: relative;
	width: 100%;
	padding: 8px 34px 8px 8px;
	border: 1px solid $gray2;
	background-color: #FFF;
	line-height: 1;
	cursor: pointer;

	@at-root {
		.el_selectWrap {
			position: relative;

			&::after {
				content: "";
				display: block;
				position: absolute;
				top: 50%;
				right: 13px;
				width: 10px;
				height: 6px;
				background-image: url(../img/down.svg);
				background-repeat: no-repeat;
				transform: translateY(-50%);
				pointer-events: none;
			}

			> select {
				min-width: 48px;
			}
		}
	}
}

canvas {
	max-width: 100%;
}