$SP: 480;
$MOBILE: 1024;

// テキスト
$base: #242424;

// 見出し・背景
$main1: #338751;
$main2: #5BA677;
$main3: #7BB992;
$main4: #EDFAED;

// ボタン
$accent1: #F3680B;
$accent2: #F3AA3D;
$accent3: #FFEBCC;
$accent4: #F3960B;
$accent5: #F3820B;
$accent6: #F39A3D;
$accent7: #F3863D;

// グラフ
$sub1: #3D4854;
$sub2: #47C5E6;
$sub3: #EBA707;
$sub4: #E2E1CB;
$sub5: #92E831;
$sub6: #F368AE;
$sub7: #8D7EC7;
$sub8: #FFDE20;

// グラフ凡例
$legend1: #225C97;
$legend2: #67A6E6;
$legend3: #A844FF;
$legend4: $sub8;
$legend5: $sub6;

// 順番指定グラフ凡例
$graph_order1:  #FE308A;
$graph_order2:  $legend3;
$graph_order3:  #0D82CF;
$graph_order4:  $sub2;
$graph_order5:  #0AA553;
$graph_order6:  $sub5;
$graph_order7:  $sub8;
$graph_order8:  #FF9F2F;
$graph_order9:  $accent1;
$graph_order10: #FE2E36;


// サイドドロワー背景色
$drawer: #172536;

// スマートフォンヘッダー
$sp_header: $drawer;

// エラー
$error: #E83131;


$red: #E61717;

// 影
$gray_16: rgba(0, 0, 0, 0.16);
$gray_10: rgba(0, 0, 0, 0.1);
$gray_05: rgba(0, 0, 0, 0.05);

// 枠線
$gray1: #EDF1F2;
$gray2: #E6E6E6;
$gray3: #F2F2F2;
$gray4: #B3B3B3;
$gray5: #808080;

// 最低幅
$min_width: 768px;


// システム全体の標準フォントサイズ
$base_font_size: 14px;


// メニューアニメーションの時間
$anim_pullDown: .3s;
$hover_opacity: .8;
$drawer_duration: .3s;
$toggleBtn_duration: .2s;